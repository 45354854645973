import React from "react";
import { Link } from "react-router-dom";
// import axios from "axios";
import Slider from "react-slick";
import { FormattedMessage } from "react-intl";
import Blogs from "../Actuality/Blogs"

const SimpleSlider = () => {
 
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };


    // const [AllBlogs, setAllBlogs] = useState([]);


    // const findAllBlogs = useCallback(() =>{
    //   axios.get("https://www.werdell.com/blogs")
    //   .then(response => response.data)
    //   .then((data)=> {
    //     setAllBlogs(data)
    //   });
     
    // },[])
    
    // useEffect(() =>{ 
    //   findAllBlogs();
    // }, [findAllBlogs]);
    


return (
  <div className="blog-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50">
    <div className="container sb-border pb-80 pb-lg-60 pb-md-50 pb-sm-40 pb-xs-30">

        <div className="row">
            <div className="col">
                <div className="section-title st-border mb-20 pt-20">
                    <h2><FormattedMessage id="article" defaultMessage="Derniers articles" /></h2>
                </div>
            </div>
        </div>
        <Slider {...settings} style={{marginTop:20}} >
          {Blogs.map((p)=>(
            <div className="blog col" key={p.idBlog}>
                <div className="blog-inner">
                    <div className="media">
                      <div className="image">
                        <img src={p.src} alt="" />
                      </div>
                    </div>
                    <div className="content">
                        <h3 className="title">
                          <Link to={{pathname: "/Blog_details/", search: `?idBlog=${p.idBlog}`}}>
                            {p.title}
                          </Link></h3>
                        <ul className="meta">
                            <li>{p.date}</li>
                        </ul> 
                        <Link to={{pathname: "/Blog_details/", search: `?idBlog=${p.idBlog}`}} className="btn"><FormattedMessage id="more" defaultMessage="Lire la suite" /></Link>
                    </div>
                </div>
            </div>
          ))}
        </Slider>
        
        {/* {Blogs.map((b)=>(
              <div key={b.src}>
                <p>{b.para2}</p>
              </div>
            ))} */}

      </div>
    </div>
    );
  }
export default SimpleSlider;