import React, {useState} from "react";
import { Link } from "react-router-dom";
// import axios from "axios";
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/iconfont.min.css"
import "../../assets/css/helper.css"
import "../../assets/css/style.css"
import bg from "../../assets/images/bg/breadcrumb.jpg";
import AllBlogs from "./AllBlogs";
import { FormattedMessage } from "react-intl";
import Blogs from "./Blogs";

const Actuality = () => {

    // const [Blogs, setBlogs] = useState([]);
    // const Blogs = useState([Blogs]);
    // const [loading, setLoading] = useState(false);
    const loading = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [blogsPerPage] = useState(4);


// useEffect(() =>{ 
//     const findAllBlogs = async () =>{
//         setLoading(true);
//         const res = await axios.get("https://www.werdell.com/blogs");
//         setBlogs(res.data);
//         setLoading(false);
//     }

//     findAllBlogs();
// }, []);


    const indextOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indextOfLastBlog - blogsPerPage;
    const currentBlogs = Blogs.slice(indexOfFirstBlog,indextOfLastBlog);

    const paginate = (pageNumber) => setCurrentPage(pageNumber)


  return (
            <div id="main-wrapper">
                <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                
                                <div className="page-banner text-center">
                                    <h2><FormattedMessage id="actuality" defaultMessage="Actualités" /></h2>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
                                        <li><FormattedMessage id="actuality" defaultMessage="Actualités" /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AllBlogs Blogs={currentBlogs} loading={loading} blogsPerPage={blogsPerPage} totalBlogs={Blogs.length} paginate={paginate} />
            </div>

      );
    };
    
export default  React.memo(Actuality);