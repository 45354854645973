
import React, {Component} from "react";
import Footer from "../Footer/Footer.js";
import Actuality from "./Actuality.js";


export default class ActualityGlobal extends Component {

    render() {
        return (
            <div>
                <Actuality />
                <Footer />
            </div>
      );
    };
    
}