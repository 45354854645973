import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import bg from "../../assets/images/bg/b-interieur.jpg";
import { FormattedMessage } from 'react-intl';



const InternSousCategories = (props) => {
    const [AllTypeCat, setAllTypeCat] = useState([]);


    const findAllCategories = useCallback(() =>{
      axios.get("https://www.werdell.com/getbycategorieName/Indoor")
      .then(response => response.data)
      .then((data)=> {
        setAllTypeCat(data)
      });
     
    },[])
    
    useEffect(() =>{ 
      findAllCategories();
    }, [findAllCategories]);
    


return (
    <div id="main-wrapper">
    <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container">
            <div className="row">
                <div className="col">
                    
                    <div className="page-banner text-center">
                        <h2><FormattedMessage id="intern" defaultMessage="Intérieur" /></h2>
                        <ul className="page-breadcrumb">
                            <li><Link to="/Tous les categories"><FormattedMessage id="prod" defaultMessage="Catégories" /></Link></li>
                            <li><FormattedMessage id="intern" defaultMessage="Intérieur" /></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div className="shop-section section" style={{marginBottom:100}}>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="shop-area sb-border pb-70 pb-lg-50 pb-md-40 pb-sm-20 pb-xs-20">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="shop-product">
                                        <div id="myTabContent-2" className="tab-content">
                                            <div id="grid" className="tab-pane fade active show">
                                                <div className="product-grid-view">
                                                    <div className="row">
                                                    {AllTypeCat.length === 0?
                                                      ""
                                                          :
                                                      AllTypeCat.map((t) => (
                                                          <div className="col-lg-3 col-md-6 col-sm-6" key={t.idTCateg}>
                                                            <div className="single-grid-product mb-30">
                                                                <div className="product-image">
                                                                    <Link to={{pathname: "/Les produits d'éclairage intérieur", search: `?type=${t.nomType}` }} >
                                                                        <img src={t.img1} className="img-fluid" alt=""/>
                                                                    </Link> 
                                                                <span className="category"></span>
                                                                <h3 className="title">{t.nomType}</h3>
                                                            </div>
                                                        </div>
                                                        </div>
                                                      ))
                                                  }
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>

  <Footer />
      
</div>
  );

}
export default  React.memo(InternSousCategories)

