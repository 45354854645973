import React from "react";
import { Switch, Route } from "react-router-dom";

import Topbar from "./Topbar";
import HomePage from "../HomePage/HomePage";
import Consultation from "../ConsultationProd/Consultation";
import ExternSousCategories from "../Exterieur/ExternSousCategories";
import ProduitParSousCatExtern from "../Exterieur/ProduitParSousCatExtern";
import ProduitParSousCatIntern from "../Interieur/ProduitParSousCatIntern";
import InternSousCategories from "../Interieur/InternSousCategories";
import About from "../AboutUs/About";
import contact from "../Contact/contact";
import ProfilesSousCategories from "../PROFILES_RUBANLED/ProfilesSousCategories";
import ProduitParSousCatProfileRubanLED from "../PROFILES_RUBANLED/ProduitParSousCatProfileRubanLED";
import ConsultationProfilesRubanLED from "../PROFILES_RUBANLED/ConsultationProfilesRubanLED";
import GenericNotFound from "./GenericNotFound";
import DocumentationBody from "../Documentation/Documentation";
import ActualityGlobal from "../Actuality/ActualityGlobal";
import BlogDetails from "../Actuality/BlogDetails";
import Politique from "../Footer/Politique";
import AllCategories from "../ConsultationProd/AllCategories";
import RegistrationLoginPage from "../RegistrationLogin.js/RegistrationLoginPage";
import ScrollArrow from "./ScrollArrow";
import SearchResult from "./SearchResult";

const Content = () => (
  <div >
    <Topbar />
    
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/AboutUs" component={About} />
        <Route exact path="/Documentation" component={DocumentationBody} />
        <Route exact path="/Actuality" component={ActualityGlobal} />
        <Route exact path="/Politique" component={Politique} />
        <Route exact path="/Accueil" component={HomePage} />
        <Route exact path="/Register and Login" component={RegistrationLoginPage} />
        <Route exact path="/Contact" component={contact} />
        <Route exact path="/intern" component={InternSousCategories} />
        <Route exact path="/extern" component={ExternSousCategories} />
        <Route exact path="/profiles" component={ProfilesSousCategories} />
        <Route exact path="/Tous les categories" component={AllCategories} />
        <Route exact path="/Blog_details" component={BlogDetails} />
        <Route exact path="/consultation de produit/:type" component={Consultation} />
        <Route exact path="/consultation de produits/:type" component={ConsultationProfilesRubanLED} />
        <Route exact path="/Les produits d'éclairage extérieur" component={ProduitParSousCatExtern} />
        <Route exact path="/Les produits d'éclairage intérieur" component={ProduitParSousCatIntern} />
        <Route exact path="/Les produits d'éclairage Profiles et RubanLed" component={ProduitParSousCatProfileRubanLED} />
        <Route exact path="/SearchResult" component={SearchResult} />
        <Route component={GenericNotFound}/>    
      </Switch>

      <ScrollArrow /> 
 
</div>
);

export default  React.memo(Content);
