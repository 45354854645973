import React, {useState} from 'react';
// import { Col, Row } from 'reactstrap';
import PDFprod from '../TechniqueFich/TechniqueFiche.js';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const DescCodPDF = (props) => {

    const [date] = useState(new Date().getDate()  + "-" + (new Date().getMonth()+1) + "-" + new Date().getFullYear());
    const [month] = useState(new Date().getMonth()+1);
    const history = useHistory();

  const FT = () =>{
    if(localStorage.getItem("username") === null || localStorage.getItem("email") === null  ){
        history.push("/Register and Login");
    }
    
}

const SendData = () =>{
    
if(localStorage.getItem("email") !== null && localStorage.getItem("username") !== null){
    const contact = {
        nomUserFT: localStorage.getItem("username"),
        emailFT: localStorage.getItem("email"),
        telFT: localStorage.getItem("tel"),
        entreprise: localStorage.getItem("entreprise"),
        date: date,
        month: month
      }
      const prodUser = {
          dateDownload: date,
      }
      let result = axios.post("https://www.werdell.com/savedataFT", contact)
      .then(result =>{
          return axios.post("https://www.werdell.com/saveProdUserFT/"+result.data.idFT+"/"+props.idProduit, prodUser).then(res => {
      }
      )});
       return result;
    
    }
}

// const Ies = () =>{

//     if(localStorage.getItem("username") === null && localStorage.getItem("email") === null  ){
//         history.push("/Register and Login");
//     }
    
//     if(localStorage.getItem("email") !== null && localStorage.getItem("username") !== null){
//         window.open(props.ies);
//     }

// }

  return(

    <div>
        {/* <div className="codeGenerateDesc">
                    <Row>
                        <Col md="6"> 
                         <p className="paraCode">  <i className="indiceCode"> Le code du produit : </i> {props.cod}</p>
                        </Col>
                        <Col md="6">
                           <p className="paraCode"> <i className="indiceCode"> Désignation : </i>  {props.desc}</p>
                        </Col>
                    </Row>
                    <Row style={{marginTop:25}}>
                        <Col md="2"> */}


{/* 
                            {props.pdfS === "pdf" ? 
                            <div>
                            {localStorage.getItem("username") !== null && localStorage.getItem("email") !== null?
                                <PDFDownloadLink document={<PDFprod yes={props.yes} douille={props.douille} doui={props.doui} ip={props.ip} certification={props.certification} angleG={props.angleG} angleGraphG={props.angleGraphG} puis={props.puis} finitionField={props.finitionField} color={props.color} driverc={props.driverc} angle={props.angle} GraphG={props.GraphG} puissance={props.puissance} sous={props.sous} codeProd={props.cod} cri={props.cri} flux={props.flux} nbr={props.nbr} />} fileName={props.cod+".pdf"}>
                                        {({ loading }) => (loading ? 'Loading document...' : 
                                            <div className="downloadFich"><FontAwesomeIcon icon={faDownload} onClick={SendData} className="DDICFich" /> Fiche Technique</div>
                                        )}
                                </PDFDownloadLink>
                                :
                                <div className="downloadFich"><FontAwesomeIcon icon={faDownload} onClick={FT} className="DDICFich" /> Fiche Technique</div>
                                
                            }
                            </div>
                                :
                                ""
                            } */}

                    {props.pdfS === "pdf" ? 
                        <div className="cart-summary">
                            {localStorage.getItem("username") !== null && localStorage.getItem("email") !== null?
                                <PDFDownloadLink document={<PDFprod yes={props.yes} douille={props.douille} doui={props.doui} ip={props.ip} certification={props.certification} angleG={props.angleG} angleGraphG={props.angleGraphG} puis={props.puis} finitionField={props.finitionField} color={props.color} driverc={props.driverc} angle={props.angle} GraphG={props.GraphG} puissance={props.puissance} sous={props.sous} codeProd={props.cod} cri={props.cri} flux={props.flux} nbr={props.nbr} />} fileName={props.cod+".pdf"}>
                            {({ loading }) => (loading ? 'Loading document...' : 
                                <div className="cart-summary-button">
                                    <div className="btn FicheTechnique" onClick={SendData}><FontAwesomeIcon icon={faDownload} /><FormattedMessage id="fiche" defaultMessage="Fiche technique" /></div>
                                </div>
                             )}
                        </PDFDownloadLink>
                                :
                                <div className="cart-summary-button">
                                    <button className="btn" onClick={FT}><FontAwesomeIcon icon={faDownload} /> <FormattedMessage id="fiche" defaultMessage="Fiche technique" /></button>
                                </div>
                        }
                        </div>
                         :
                         ""
                     }
{/*                             
                        </Col>
                            <Col md="2" style={{display:props.ies !== null && props.ies !== "" && props.ies !== "NULL"?"":"none"}}>
                            <div className="downloadFich"> <FontAwesomeIcon onClick={Ies} icon={faDownload} className="DDICFich" /> Fichier IES </div>
                            </Col>
                    </Row>
                    </div> */}
    </div>
  );

}

export default React.memo(DescCodPDF)


