import React, {useState, useEffect, useCallback} from 'react';
// import React from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import bg from "../../assets/images/bg/breadcrumb.jpg";
import banner1 from "../../assets/images/interieur.jpg";
import banner2 from "../../assets/images/exterieur.jpg";
import banner3 from "../../assets/images/Profile-ruban.jpg";
import { FormattedMessage } from 'react-intl';


const AllCategories = (props) => {
    // eslint-disable-next-line
     const [Intern, setIntern] = useState([]);
     // eslint-disable-next-line
     const [Extern, setExtern] = useState([]);
     // eslint-disable-next-line
     const [Profiles, setProfiles] = useState([]);


     const AllTypeCat = useCallback(() =>{
       axios.get("https://www.werdell.com/getbycategorieName/Indoor")
       .then(response => response.data)
       .then((data)=> {
         setIntern(data)
       });

       axios.get("https://www.werdell.com/getbycategorieName/Outdoor")
       .then(response => response.data)
       .then((data)=> {
         setExtern(data)
       });

       axios.get("https://www.werdell.com/getbycategorieName/PROFILES")
       .then(response => response.data)
       .then((data)=> {
         setProfiles(data)
       });
     
     },[])
    
     useEffect(() =>{ 
         AllTypeCat();
     }, [AllTypeCat]);
    


return (
    <div id="main-wrapper">
    <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="page-banner text-center">
                        <h2><FormattedMessage id="prod" defaultMessage="Catégories" /></h2>
                        <ul className="page-breadcrumb">
                            <li><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
                            <li><FormattedMessage id="prod" defaultMessage="Catégories" /></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="banner-section section pt-30">
    <div className="container-fluid pl-20 pr-20 pl-lg-15 pr-lg-15 pl-md-15 pr-md-15 pl-sm-15 pr-sm-15 pl-xs-15 pr-xs-15" style={{width:"92%"}}>
        <div className="row CCategories">
            <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to="/intern">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                         <img src={banner1} alt="" height="400px" className="Categories" />
                    </div>
                    <div className="banner-content">
                        <h3 className="title bannerC"><FormattedMessage id="banner1" defaultMessage="Produits d'intérieur" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to="/extern">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner2} alt="" height="400px" className="Categories" />
                    </div>
                    <div className="banner-content">
                        <h3 className="title bannerC"><FormattedMessage id="banner2" defaultMessage="Produits d'extérieur" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
            <Link to="/profiles">
                <div className="single-banner-item mb-30">
                    <div className="banner-image">
                        <img src={banner3} alt="" height="400px" className="Categories" />
                    </div>
                    <div className="banner-content">
                        <h3 className="title bannerC"><FormattedMessage id="banner3" defaultMessage="Profiles et Rubans LED" /></h3>
                    </div>
                </div>
              </Link>
            </div>
        </div>
    </div>
</div>


{/* <div className="shop-section section" style={{marginBottom:100}}>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="shop-area sb-border pb-70 pb-lg-50 pb-md-40 pb-sm-20 pb-xs-20">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="shop-product">
                                        <div id="myTabContent-2" className="tab-content">
                                            <div id="grid" className="tab-pane fade active show">
                                                <div className="product-grid-view">
                                                    <div className="row">
                                                    {Intern.length === 0?
                                                      ""
                                                          :
                                                      Intern.map((t) => (
                                                          <div className="col-lg-3 col-md-6 col-sm-6" key={t.idTCateg}>
                                                            <div className="single-grid-product mb-30">
                                                                <div className="product-image">
                                                            <Link to={{pathname: "/Les produits d'éclairage intérieur", search: `?type=${t.nomType}` }} >
                                                                  <img src={t.img1} className="img-fluid" alt=""/>
                                                            </Link> 
                                                                  <span className="category"></span>
                                                                    <h3 className="title">{t.nomType}</h3>
                                                          </div>
                                                        </div>
                                                        </div>
                                                      ))
                                                  }
                                                    {Extern.length === 0?
                                                      ""
                                                          :
                                                      Extern.map((t) => (
                                                          <div className="col-lg-3 col-md-6 col-sm-6" key={t.idTCateg}>
                                                            <div className="single-grid-product mb-30">
                                                                <div className="product-image">
                                                            <Link to={{pathname: "/Les produits d'éclairage extérieur", search: `?type=${t.nomType}` }} >
                                                                  <img src={t.img1} className="img-fluid" alt=""/>
                                                            </Link> 
                                                                  <span className="category"></span>
                                                                    <h3 className="title">{t.nomType}</h3>
                                                          </div>
                                                        </div>
                                                        </div>
                                                      ))
                                                  }
                                                    {Profiles.length === 0?
                                                      ""
                                                          :
                                                      Profiles.map((t) => (
                                                          <div className="col-lg-3 col-md-6 col-sm-6" key={t.idTCateg}>
                                                            <div className="single-grid-product mb-30">
                                                                <div className="product-image">
                                                            <Link to={{pathname: "/Les produits d'éclairage Profiles et RubanLed", search: `?type=${t.nomType}` }} >
                                                                  <img src={t.img1} className="img-fluid" alt=""/>
                                                            </Link> 
                                                                  <span className="category"></span>
                                                                    <h3 className="title">{t.nomType}</h3>
                                                          </div>
                                                        </div>
                                                        </div>
                                                      ))
                                                  }
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div> */}

  <Footer />
      
</div>
  );

}
export default  React.memo(AllCategories)

