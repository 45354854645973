import React, {useState, useEffect, useCallback, useContext} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import close from "../../assets/images/close.png";
import logo from "../../assets/images/logo.png";
// import eng from "../../assets/images/icons/en-gb.png";
// import fr from "../../assets/images/icons/fr-fr.png";
import TopbarMobile from "./TopbarMobile";
import SearchComponent from "./SearchComponent";
import { Context } from "../Wraper";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUserCircle } from '@fortawesome/free-solid-svg-icons'


const Topbar = () => {

const [CategoryExtern, setAllCategoryExtern] = useState([]);
const [CategoryIntern, setAllCategoryIntern] = useState([]);
const [CategoryPR, setAllCategoryPR] = useState([]);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [showResults, setShowResults] = React.useState(false)
  const onClickMe = () => setShowResults(!showResults)
  const closeContent = (result) => setClick(result);

  const context = useContext(Context);


  const findAllCategories = useCallback(() =>{
    axios.get("https://www.werdell.com/getbycategorieName/Outdoor")
    .then(response => response.data)
    .then((data)=> {
      setAllCategoryExtern(data)
    });

    axios.get("https://www.werdell.com/getbycategorieName/Indoor")
    .then(response => response.data)
    .then((data)=> {
      setAllCategoryIntern(data)
    });

    axios.get("https://www.werdell.com/getbycategorieName/PROFILES")
    .then(response => response.data)
    .then((data)=> {
      setAllCategoryPR(data)
    });
   
  },[])
  
  useEffect(() =>{ 
    findAllCategories();
  }, [findAllCategories]);
  

  const [NavBar, setNavBar] = useState(false);
  const navbarSticky = () =>{
     if(window.scrollY >= 90){
       setNavBar(true);
     }
     else{
       setNavBar(false);
     }
  };
  window.addEventListener('scroll', navbarSticky);
  
  const Quite = (send) => setShowResults(send);

  return (
  <div>
           
    { showResults ?        
      <div className="searchComponent">
          <img src={close} alt="" onClick={onClickMe}/>
        <div className="searchBody">
          <SearchComponent Quite={Quite} />
        </div>
      </div>  : <div></div>}

    <div>
        <header className={NavBar ? 'header position-fixed header-sticky d-none d-lg-block NavBar' : 'header position-relative header-sticky d-none d-lg-block'}>
            <div className="header-default">
                <div className="container-fluid pl-115 pl-lg-15 pl-md-15 pl-sm-15 pl-xs-15 pr-115 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="header-nav d-flex justify-content-between align-items-center">
                                <div className="header-logo text-center">
                                <Link to="/Accueil"><img src={logo} alt="" /></Link>
                                </div>
        <nav className="main-menu main-menu-two">
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li onClick={closeMobileMenu}><Link to="/Accueil"><FormattedMessage id="home" defaultMessage="Accueil" /></Link></li>
            <li onClick={closeMobileMenu}><Link to="/Tous les categories"><FormattedMessage id="produit" defaultMessage="Produits" /></Link>
                <ul className="mega-menu four-column">
                    <li onClick={closeMobileMenu}><Link to="/intern" className="item-link"><FormattedMessage id="intern" defaultMessage="Intérieur" /></Link>
                    {CategoryIntern.map( (I) =>(
                        <ul key={I.idTCateg}>
                          <li onClick={closeMobileMenu}><Link to={{pathname: "/Les produits d'éclairage intérieur", search: `?type=${I.nomType}` }} >{I.nomType}</Link></li>
                        </ul>
                       )) 
                      }
                    </li>
                    <li onClick={closeMobileMenu}>
                      <Link to="/extern" className="item-link"><FormattedMessage id="extern" defaultMessage="Extérieur" /></Link>
                      {CategoryExtern.map( (E) =>(
                        <ul key={E.idTCateg}>
                          <li onClick={closeMobileMenu}><Link to={{pathname: "/Les produits d'éclairage extérieur", search: `?type=${E.nomType}` }} >{E.nomType}</Link></li>
                        </ul>
                       )) 
                      }
                    </li>
                    <li onClick={closeMobileMenu}>
                      <Link to="/profiles" className="item-link">PROFILES / RUBANS LED</Link>
                      {CategoryPR.map( (p) =>(
                        <ul key={p.idTCateg}>
                          <li onClick={closeMobileMenu}> <Link to={{pathname: "/Les produits d'éclairage Profiles et RubanLed", search: `?type=${p.nomType}` }} >{p.nomType}</Link></li>
                        </ul>
                       )) 
                      }
                    </li>
                </ul>
            </li>
            <li onClick={closeMobileMenu}><Link to="/AboutUs"><FormattedMessage id="about" defaultMessage="À Propos" /></Link></li>
            <li onClick={closeMobileMenu}><Link to="/Actuality"><FormattedMessage id="actuality" defaultMessage="Actualités" /></Link></li>
            <li onClick={closeMobileMenu}><Link to="/Contact">Contact</Link></li>
            </ul>
        </nav>
          <div className="header-right_wrap d-flex">
              <div className="header-search">
                  <button onClick={onClickMe} className="header-search-toggle">
                    <FontAwesomeIcon icon={faSearch} style={{transform:"scale(1.5,1.5)",marginBottom:-2}} />
                  </button>
                  <div className="header-search-form">
                      <form>
                          <button ><i className="ion-ios-search-strong"></i></button>
                      </form>
                  </div>
              </div>
              <ul className="ht-us-menu">
                  <li><Link to="/Register and Login">
                  <FontAwesomeIcon icon={faUserCircle} style={{transform:"scale(0.9,0.9)",marginTop:8}} /></Link></li>
              </ul>
              <ul className="ht-us-menu">
                  <li className="myLang">
                    <select value={context.locale} onChange={context.selectLang} className="selectLang">
                      <option value="fr-CA"  className="selectopt">Français</option>
                      <option value="en-US"  className="selectopt">English</option>
                    </select>
                  </li>
              </ul>

              {/* <ul className="ht-us-menu" value={context.locale}>
                  <li onClick={closeMobileMenu} value="en-US" onChange={context.selectLang} ><img src={eng} alt="" /></li>
              </ul>
              <ul className="ht-us-menu" value={context.locale}>
                  <li onClick={closeMobileMenu} value="fr-CA" onChange={context.selectLang} ><img src={fr} alt="" /></li>
              </ul> */}
          </div>

      </div>
      </div>
      </div>
      </div>
      </div>
      </header>

      </div>


      <div className={click ? "mobile-menu fixedMobile": "mobile-menu"} onClick={handleClick}>
          <img src={logo} alt="" className="mymobileLogo" />
      </div>
      {click ? (
          <div className={click ? "mobile-menu fixedMobile": "mobile-menu"}>
            <div style={{width:"100%"}} onClick={handleClick} >
              <img src={logo} alt="" className="mymobileLogo" />
            </div>
            <TopbarMobile closeContent={closeContent} Quite={Quite} />
          </div>
        ) : (
          ""
        )}

    </div>
  );
};

export default Topbar;

