import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios";
import ConsultationDescription from './ConsultationDescription';
import ProfilesGenerator from './ProfilesGenerator';
import Footer from '../Footer/Footer';
import bg from "../../assets/images/bg/b-profil.jpg";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/iconfont.min.css";
import "../../assets/css/helper.css";
import "../../assets/css/style.css";
import { useHistory } from "react-router-dom";
// import PlusDeProfiles from './PlusDeProfiles';


const ConsultationProfilesRubanLED = (props) => {

    const [produits, setproduits] = useState([]);
    const [color, setcolor] = useState([]);
    const [Color, setColor] = useState('');
    const [driver, setdriver] = useState([]);
    const [Driver, setDriver] = useState('');
    const [puissance, setpuissance] = useState([]);
    const [Puissance, setPuissance] = useState('');
    const [angle, setangle] = useState([]);
    const [Angle, setAngle] = useState('');
    const [angleGraph, setangleGraph] = useState([]);
    const [GGraph, setGGraph] = useState('');
    const [cri, setcri] = useState([]);
    const [CRI, setCRI] = useState('');
    // const [killed, setKilled] = useState([]);
    // const [typeName, settypeName] = useState('');
    const [certification, setcertification] = useState([]);
    const [typeR, settypeR] = useState('');
    const [ip, setip] = useState([]);
    const [Ip, setIp] = useState([]);
    const [longeur, setlongeur] = useState([]);
    const [Longeur, setLongeur] = useState('');
    const [formDiff, setformDiff] = useState([]);
    const [FormDiff, setFormDiff] = useState('');
    const [natureDiff, setnatureDiff] = useState([]);
    const [NatureDiff, setNatureDiff] = useState('');
    const [typeSource, settypeSource] = useState([]);
    const [TypeSource, setTypeSource] = useState('');

    const history = useHistory();
    

const findProdByIdSentInLink = useCallback((prodId) =>{

    axios.get("https://www.werdell.com/getidP/"+prodId)
    .then(response => response.data)
    .then((data)=> {
        setproduits(data);
        // setprodName(data.nomProduit);
        // settypeName(data.typecategorie.categorie.nomCategorie);
})
    axios.get("https://www.werdell.com/getAngleByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setangle(data);
            if(data.length=== 1){
                for (const dataObj of data) {
                    setAngle(dataObj.angleName);
                }
            }
        })
    axios.get("https://www.werdell.com/getAngleGraphByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setangleGraph(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setGGraph(dataObj.angleGraphName);
                }
            }
        })
    axios.get("https://www.werdell.com/getCertificationByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setcertification(data);
        })
    axios.get("https://www.werdell.com/getColorByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setcolor(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setColor(dataObj.colorName);
                }
            }
        })
    axios.get("https://www.werdell.com/getCriByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setcri(data);
            if(data.length ===1){
                for (const dataObj of data) {
                   setCRI(dataObj.criName);
                }
            }
        })
    axios.get("https://www.werdell.com/getDriverByProduit/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setdriver(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setDriver(dataObj.driverName);
                }
            }
        })
        axios.get("https://www.werdell.com/getIpByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setip(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setIp(dataObj.nombre);
                }
            }
        })
    axios.get("https://www.werdell.com/getdatapuissanceProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setpuissance(data);
            if(data.length === 1){
                for (const dataObj of data) {
                    setPuissance(dataObj.puissanceName);
                }
            }
        })
        axios.get("https://www.werdell.com/getlongeurByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setlongeur(data);
             if(data.length ===1){
                for (const dataObj of data) {
                   setLongeur(dataObj.longeurName);
                }
            }
        })
        axios.get("https://www.werdell.com/getTypeSourceByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            settypeSource(data);
             if(data.length ===1){
                for (const dataObj of data) {
                   setTypeSource(dataObj.typeSource);
                }
            }
        })
        axios.get("https://www.werdell.com/getFormDiffByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setformDiff(data);
             if(data.length ===1){
                for (const dataObj of data) {
                   setFormDiff(dataObj.formDiff);
                }
            }
        })
        axios.get("https://www.werdell.com/getnaturediffByProd/"+prodId)
        .then(response => response.data)
        .then((data)=> {
            setnatureDiff(data);
             if(data.length ===1){
                for (const dataObj of data) {
                   setNatureDiff(dataObj.natureDiff);
                }
            }
        })
},[])


// const getAllProd = useCallback((type) =>{
//     axios.get("https://www.werdell.com/getbyType/"+type)
//     .then(response => response.data)
//     .then((data)=> {
//         setKilled(data);
//     })
// },[])

useEffect(() =>{ 
    let prodId = new URLSearchParams(props.location.search).get('idP');
    findProdByIdSentInLink(prodId);
    settypeR(props.match.params.type);
    // if(props.match.params.type){
    //     let type = props.match.params.type;
    //     getAllProd(type);
    // }
}, [props.location.search,findProdByIdSentInLink,props.match.params]);



  return (
    <div id="main-wrapper">
    <div className="page-banner-section section bg-image" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container">
            <div className="row">
                <div className="col">
                    
                    <div className="page-banner text-center">
                        <h2>{produits.nomProduit}</h2>
                        <ul className="page-breadcrumb">
                        <li className="gobackcategory" onClick={() => history.goBack()}>{typeR}</li>
                            <li>{produits.nomProduit}</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    <div style={{marginBottom:130}}>
        
        <ConsultationDescription details={produits} puissance={puissance} angleGraph={angleGraph} angle={angle} color={color}  driver={driver} cri={cri} />
        <ProfilesGenerator ip={ip} Ip={Ip} type={typeR} longeur={longeur} Longeur={Longeur} typeSource={typeSource} TypeSource={TypeSource} formDiff={formDiff} FormDiff={FormDiff} natureDiff={natureDiff} NatureDiff={NatureDiff} certification={certification} CRIT={CRI} ColorT={Color} AngleT={Angle} GGraphT={GGraph} DriverT={Driver} PuissanceT={Puissance} typeName={typeR} data={produits} puissance={puissance} angle={angle} angleGraph={angleGraph} color={color} driver={driver} cri={cri} />
        {/* <div style={{marginTop:60}}>
            <PlusDeProfiles type={typeR} data={killed} />
        </div> */}
    </div>

    <Footer />
</div>
  );

}
export default  React.memo(ConsultationProfilesRubanLED)

